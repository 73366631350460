























import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import VesselDataTable from "@/components/vessel/VesselDataTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import VesselCreateDialog from "@/components/dialogs/VesselCreateDialog.vue";
import { VesselModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import Discriminator from '@/types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { VesselCreateDialog, VesselDataTable }
})
export default class VesselOverview extends Vue {
  public tableRefreshKey = false;
  public createDialog = false;
  public loading = false;

  get discriminator() {
    return Discriminator.LAND_WORKERS
  }

  public async onDownloadTermsConditions(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesseltermsconditionsIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} T&C.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }
  public async onDownloadOwnerInstruction(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesselownerinstructionIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} Power Of Attorney.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }
  public async onDownloadVmsRequestForm(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesselvmsrequestformIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} VMS Request Form.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }
  public onCompanyUpdate(item: VesselModel) {
    if (!item.vesselId) {
      return;
    }

    this.$router.push({
      name: "Company Edit",
      params: { vesselId: item.vesselId }
    });
  }

  public async onVesselDelete(item: VesselModel) {
    if (!item.vesselId) {
      return;
    }

    try {
      await Api.VesselService.apiVesselVesselIdDelete(item.vesselId);
      snackbarModule.setSnackbarMessage("company deleted");
      this.tableRefreshKey = !this.tableRefreshKey;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete company");
    }
  }
}
